<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    max-width="290px"
    min-width="auto"
    :close-on-content-click="false"
    offset-y
    :right="right"
    :left="left"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="fecha"
        :label="label"
        :clearable="clearable"
        :hide-details="hideDetails"
        readonly
        outlined
        filled
        dense
      >
        <template v-slot:append-outer>
          <v-icon
            v-bind="attrs"
            v-on="on"
            :color="disabled ? '' : 'info'"
            :title="disabled ? '' : 'Seleccionar fecha'"
            :disabled="disabled"
            @click="date = parseDate(fecha)"
          >
            fas fa-calendar-day
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="parseDate(fecha_minima)"
      no-title
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { readonly } from 'vue';

/**
 * Componente para seleccionar una fecha. Devuelve la fecha en formato DD/MM/AAAA
 */
export default {
  data () {
    return {
      menu: false,
      date: null
    }
  },
  props: {
    value: String, // obligatorio, puede enviarse null o con una fecha predefinida
    label: {
      type: String,
      default: ''
    },
    fecha_minima: { // fecha mínima seleccionable en formato dd/mm/aaaa
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },

    right: {
      type: Boolean,
      default: true
    },
  },
  watch: {
    date(val) {
      this.fecha = this.formatDate(val);
      this.$emit('change', this.fecha); 
    },
  },

  computed: {
    fecha: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
}
</script>
