<template>
  <div>
    <v-dialog v-model="dialog" max-width="780" scrollable>
      <v-card>
        <v-card-title class="d-flex mt-0 mx-md-2 mx-n1 mb-0">
          Nuevo Precio - Modelo
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" sm="3" md="3" class="py-1">
              Empresa
              <v-autocomplete
                @change="setMarcas"
                v-model="data.empresa"
                item-text="nombre"
                return-object
                :items="familias"
                hide-details
                outlined
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="py-1">
              Marca
              <v-autocomplete
                @change="setCodigoModelos"
                v-model="data.marca"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :items="
                  marcas.filter(
                    data.empresa.id
                      ? (marca) => marca.familia == data.empresa.id
                      : (marca) => !marca
                  )
                "
                hide-details
                outlined
                dense
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-1">
              Codigo Modelo
              <v-autocomplete
                v-model="data.codigoModelo"
                item-text="Modelo"
                item-value="Modelo"
                tabindex="1"
                :items="codigosModelos"
                hide-details
                outlined
                dense
                clearable
                @change="setModelos"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="py-1">
              Modelo
              <v-text-field
                v-model="data.modelo"
                tabindex="1"
                hide-details
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="py-1">
              Fecha desde
              <FechaPicker v-model="data.desde" />
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-1">
              Fecha hasta
              <FechaPicker v-model="data.hasta" />
            </v-col>

            <v-col cols="12" sm="3" md="3" class="py-1">
              Precio
              <TextFieldMoney
                v-model="data.precio"
                type="number"
                v-bind:properties="{
                  prefix: '$',
                  outlined: true,
                  dense: true,
                }"
              />
            </v-col>
          </v-row>
          <div style="display: flex; justify-content: end; margin-top: 1rem">
            <v-btn class="mr-2" color="secondary" @click="dialog = !dialog">
              Volver
            </v-btn>
            <BtnConfirmar @action="guardarPrecio()" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FechaPicker from "../util/FechaPicker.vue";
import moment from "moment";
import BtnConfirmar from "../util/BtnConfirmar.vue";
import TextFieldMoney from "../../components/util/TextFieldMoney.vue";

export default {
  data() {
    return {
      data: {
        empresa: {},
        marca: null,
        codigoModelo: null,
        modelo: null,
        desde: moment().format("DD/MM/YYYY"),
        hasta: "",
        precio: null,
      },

      validacionIntervalo: true,

      marcas: null,

      modelosSeteados: [],
      modelosFiltrados: [],
      codigosModelos: [],

      preciosSeteados: [],
      preciosFiltrados: [],
    };
  },

  methods: {
    setMarcas() {
      if (this.data.empresa.id) {
        this.data.marca = null;

        let marca = this.marcas.filter(
          (marca) => marca.familia == this.data.empresa.id
        );

        marca.length == 1 ? (this.data.marca = marca[0].id) : null;
      }
      if (this.data.marca > 0) {
        this.setCodigoModelos();
      }
    },

    setCodigoModelos() {
      if (this.data.marca) {
        this.codigosModelos = this.modelosSeteados.filter(
          (modelo) => this.data.marca == modelo.Empresa_id
        );
      } else {
        this.codigosModelos = [];
      }
    },

    setModelos() {
      if (this.data.codigoModelo) {
        this.modelosFiltrados = this.modelosSeteados.filter((modelo) => {
          return this.data.codigoModelo == modelo.Modelo;
        });

        this.data.modelo = this.modelosFiltrados[0].Descripcion;
      } else {
        this.data.modelo = null;
        this.modelosFiltrados = [];
      }
    },

    async guardarPrecio() {
      if (this.validacionIntervalo) {
        this.data.modelo = this.modelosFiltrados[0].Modelo_id;

        this.$store.state.loading = true;
        this.$store
          .dispatch("preciosModelos/postPreciosModelos", this.data)
          .then((res) => {
            if (res.exito) {
              this.$emit("precioGuardado", this.data);

              this.$store.state.loading = false;
              this.$swal.fire({
                icon: "success",
                title: res.message,
              });
            } else {
              this.$store.state.loading = false;
              this.$store.dispatch("show_snackbar", {
                text: res.message,
                color: "error",
              });
            }

            this.dialog = false;
          });
      } else {
        this.$store.dispatch("show_snackbar", {
          text: "El período seleccionado ya existe",
          color: "warning",
        });
      }
    },
  },
  created() {
    this.marcas = this.$store.state.empresas;
    if (this.familias.length == 1) {
      this.data.empresa = this.familias[0].id;
      this.setMarcas();
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState(["familias", "empresas"]),
  },

  watch: {
    dialog(value) {
      if (!value) {
        if (this.familias.length == 1) {
          this.data.empresa = this.familias[0];
        } else {
          this.data.empresa = {};
        }
        this.data.marca = null;
        this.data.codigoModelo = null;
        this.data.modelo = null;
        this.data.desde = moment().format("DD/MM/YYYY");
        this.data.hasta = "";
        this.data.precio = null;
      } else {
        this.modelosSeteados = this.modelos;
        this.preciosSeteados = this.precios;
      }
    },

    "data.marca"(val) {
      this.data.codigoModelo = null;
      this.data.modelo = null;
    },

    "data.codigoModelo"(val) {
      this.data.hasta = "";
      if (!val) {
        this.setCodigoModelos();
      }
    },

    "data.modelo"(val) {
      if (!val) {
        this.setModelos();
      }
    },

    "data.hasta"(val) {
      if (
        val &&
        Object.keys(this.data.empresa).length > 0 &&
        this.data.marca &&
        this.data.codigoModelo &&
        this.data.modelo
      ) {
        this.preciosFiltrados = this.preciosSeteados.filter(
          (precio) => precio.Modelo == this.data.codigoModelo
        );

        if (this.preciosFiltrados.length > 0) {
          const fechaDesde = moment(this.data.desde, "DD/MM/YYYY");
          const fechaHasta = moment(this.data.hasta, "DD/MM/YYYY");

          if (!fechaDesde.isValid() || !fechaHasta.isValid()) {
            this.$store.dispatch("show_snackbar", {
              text: "Fechas inválidas",
              color: "warning",
            });
            return;
          }

          const periodoExiste = this.preciosFiltrados.some((precio) => {
            const fechaPrecioDesde = moment(precio.fecha_desde, "DD/MM/YYYY");
            const fechaPrecioHasta = moment(precio.fecha_hasta, "DD/MM/YYYY");

            return (
              (fechaDesde.isSameOrAfter(fechaPrecioDesde) &&
                fechaDesde.isSameOrBefore(fechaPrecioHasta)) ||
              (fechaHasta.isSameOrAfter(fechaPrecioDesde) &&
                fechaHasta.isSameOrBefore(fechaPrecioHasta)) ||
              (fechaDesde.isBefore(fechaPrecioDesde) &&
                fechaHasta.isAfter(fechaPrecioHasta))
            );
          });

          if (periodoExiste) {
            this.$store.dispatch("show_snackbar", {
              color: "orange",
              text: "El período seleccionado ya existe.",
            });
            this.validacionIntervalo = false;
          } else {
            this.validacionIntervalo = true;
          }
        }
      }
    },
  },

  props: {
    value: Boolean,
    modelos: Array,
    precios: Array,
  },

  components: {
    FechaPicker,
    BtnConfirmar,
    TextFieldMoney,
  },
};
</script>
