var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"780","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex mt-0 mx-md-2 mx-n1 mb-0"},[_vm._v(" Nuevo Precio - Modelo "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Empresa "),_c('v-autocomplete',{attrs:{"item-text":"nombre","return-object":"","items":_vm.familias,"hide-details":"","outlined":"","dense":""},on:{"change":_vm.setMarcas},model:{value:(_vm.data.empresa),callback:function ($$v) {_vm.$set(_vm.data, "empresa", $$v)},expression:"data.empresa"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Marca "),_c('v-autocomplete',{attrs:{"item-text":"nombre","item-value":"id","tabindex":"1","items":_vm.marcas.filter(
                  _vm.data.empresa.id
                    ? (marca) => marca.familia == _vm.data.empresa.id
                    : (marca) => !marca
                ),"hide-details":"","outlined":"","dense":"","clearable":""},on:{"change":_vm.setCodigoModelos},model:{value:(_vm.data.marca),callback:function ($$v) {_vm.$set(_vm.data, "marca", $$v)},expression:"data.marca"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Codigo Modelo "),_c('v-autocomplete',{attrs:{"item-text":"Modelo","item-value":"Modelo","tabindex":"1","items":_vm.codigosModelos,"hide-details":"","outlined":"","dense":"","clearable":""},on:{"change":_vm.setModelos},model:{value:(_vm.data.codigoModelo),callback:function ($$v) {_vm.$set(_vm.data, "codigoModelo", $$v)},expression:"data.codigoModelo"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Modelo "),_c('v-text-field',{attrs:{"tabindex":"1","hide-details":"","outlined":"","dense":"","readonly":""},model:{value:(_vm.data.modelo),callback:function ($$v) {_vm.$set(_vm.data, "modelo", $$v)},expression:"data.modelo"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Fecha desde "),_c('FechaPicker',{model:{value:(_vm.data.desde),callback:function ($$v) {_vm.$set(_vm.data, "desde", $$v)},expression:"data.desde"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Fecha hasta "),_c('FechaPicker',{model:{value:(_vm.data.hasta),callback:function ($$v) {_vm.$set(_vm.data, "hasta", $$v)},expression:"data.hasta"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Precio "),_c('TextFieldMoney',{attrs:{"type":"number","properties":{
                prefix: '$',
                outlined: true,
                dense: true,
              }},model:{value:(_vm.data.precio),callback:function ($$v) {_vm.$set(_vm.data, "precio", $$v)},expression:"data.precio"}})],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"end","margin-top":"1rem"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v(" Volver ")]),_c('BtnConfirmar',{on:{"action":function($event){return _vm.guardarPrecio()}}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }