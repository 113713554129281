<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-scale-transition origin="center center">
            <v-btn
              v-if="liquidaciones.length > 0 && elimina && permiso && liquidacion_id != null"
              style="position: absolute; top: -15px; right: 220px; z-index: 1"
              color="error"
              title="Eliminar liquidación"
              small
              dark
              fab
              @click="eliminar_liquidacion()"
            >
              <v-icon>fas fa-trash</v-icon>
            </v-btn>
          </v-scale-transition>
          <v-scale-transition origin="center center">
            <v-btn
              v-if="filtro.familia != null"
              style="position: absolute; top: -15px; right: 165px; z-index: 1"
              color="red darken-4"
              title="Descargar informe"
              small
              dark
              fab
              @click="dialog_descargar_pdf = true"
            >
              <v-icon>fas fa-file-pdf</v-icon>
            </v-btn>
          </v-scale-transition>
          <v-btn
            style="position: absolute; top: -15px; right: 110px; z-index: 1"
            color="info"
            title="Descargar liquidación"
            small
            dark
            fab
            @click="dialog_descargar = true"
          >
            <v-icon>fas fa-file-download</v-icon>
          </v-btn>
          <v-scale-transition origin="center center">
            <v-btn
              v-if="permiso"
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              title="Generar liquidación"
              small
              fab
              @click="dialog_nueva = true"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </v-scale-transition>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Periodo
                  <PeriodoPicker
                    v-model="filtro.periodo"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.familia"
                    item-value="id"
                    item-text="nombre"
                    :items="familias"
                    hide-details
                    outlined
                    dense
                    @change="filtro.empresa = null; filtro.supervisor = null; filtro.vendedor = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Marca
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-value="id"
                    item-text="nombre"
                    :items="empresas.filter(emp => emp.familia == filtro.familia)"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.vendedor = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Supervisor
                  <v-autocomplete
                    v-model="filtro.supervisor"
                    item-value="id"
                    item-text="nombre"
                    :items="supervisores.filter(sup => sup.familia_id == filtro.familia)"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.vendedor = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Vendedor
                  <v-autocomplete
                    v-model="filtro.vendedor"
                    item-value="id"
                    item-text="nombre"
                    :items="vendedores.filter(v => v.supervisor == filtro.supervisor || v.empresas.find(e => e == filtro.empresa))"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" xl="2" class="py-1">
                  Concepto
                  <v-autocomplete
                    v-model="filtro.concepto"
                    item-value="id"
                    item-text="nombre"
                    :items="conceptos"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" xl="2" class="py-1">
                  Titular
                  <v-text-field
                    v-model.trim="filtro.titular"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" xl="2" class="py-1">
                  Modelo
                  <v-text-field
                    v-model.trim="filtro.modelo"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="5" sm="2" xl="1" class="py-1">
                  Suscripción
                  <v-text-field
                    v-model.trim="filtro.preimpreso"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 my-2"
        :items="cabecera"
        :headers="headers"
        :loading="load"
        :expanded.sync="expanded"
        :item-class="itemRowBackground"
        :single-expand="!expandir_todos"
        :items-per-page="-1"
        hide-default-footer
        show-expand
        dense
      >
        <template v-slot:top>
          <div class="pt-1">
            <v-btn
              title="Contraer todos"
              :disabled="cabecera.length == 0"
              icon
              @click="contraer()"
            >
              <v-icon>fas fa-angle-double-up</v-icon>
            </v-btn>
            <v-btn
              title="Expandir todos"
              :disabled="cabecera.length == 0"
              icon
              @click="expandir()"
            >
              <v-icon>fas fa-angle-double-down</v-icon>
            </v-btn>
          </div>
        </template>
        <!-- row de totales -->
        <template slot="body.append">
          <tr>
            <th></th>
            <th style="font-size: 14px">Total</th>
            <th></th>
            <th style="font-size: 14px" class="text-right">
              {{ formatMoney(total) }}
            </th>
          </tr>
        </template>
        <!-- template para formatear montos -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <!-- sub tabla del detalle -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-data-table
              class="cebra my-2"
              sort-by="concepto"
              :items="liquidaciones.filter(l => l.liq_vend_id == item.id)"
              :headers="headers_detalle"
              :items-per-page="-1"
              :item-class="itemRowBackgroundDetalle"
              hide-default-footer
              dense
              @contextmenu:row="click_derecho"
            >
              <!-- template para formatear montos -->
              <template
                v-for="header in headers_detalle.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <template v-slot:[`item.grupo_orden`]="{ item }">
                {{ item.grupo && item.orden ? `${item.grupo}/${item.orden}` : '' }}
              </template>
              <template v-slot:[`item.porcentaje`]="{ item }">
                {{ (item.porcentaje * 100).toFixed(1) }}%
              </template>
              <template v-slot:[`item.comentario`]="{ item }">
                <v-tooltip
                  v-if="item.comentario"
                  color="primary"
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                      style="cursor: pointer"
                      small
                    >
                      fas fa-comment-dots
                    </v-icon>
                  </template>
                  <div>
                    {{ item.comentario }}
                  </div>
                </v-tooltip>
                <v-icon
                  v-else
                  color="disabled"
                  small
                >
                  fas fa-comment-dots
                </v-icon>
              </template>
              <template v-slot:[`item.debito`]="{ item }">
                <v-icon
                  v-if="item.debito === 1 || item.debito === 0"
                  :color="item.debito == 1 ? 'success' : 'disabled'"
                  small
                >
                  fas fa-{{ item.debito == 1 ? 'check' : 'minus' }}-circle
                </v-icon>
              </template>
              <!-- row de totales -->
              <template slot="body.append">
                <tr>
                  <th style="font-size: 14px">Total</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style="font-size: 14px" class="text-right">
                    {{ sumarTotal(liquidaciones.filter(l => l.liq_vend_id == item.id)) }}
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </template>
            </v-data-table>
          </td>
        </template>
        <!-- no data -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- MENU DEL CLIK DERECHO -->
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          :disabled="!(elimina && permiso)"
          @click="dialog_am = true; item_selected.preimpreso = null"
        >
          <v-list-item-title>
            <v-icon :disabled="!(elimina && permiso)" color="success" small left>fas fa-plus</v-icon>
            Agregar
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="item_selected.sin_detalle == 1"
          @click="dialog_detalle = true"
        >
          <v-list-item-title>
            <v-icon :disabled="item_selected.sin_detalle == 1" color="info" small left>fas fa-eye</v-icon>
            Ver
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!(elimina && permiso && item_selected.sin_detalle != 1)"
          @click="dialog_am = true"
        >
          <v-list-item-title>
            <v-icon :disabled="!(elimina && permiso && item_selected.sin_detalle != 1)" color="indigo" small left>fas fa-pen</v-icon>
            Editar
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!(elimina && permiso && item_selected.sin_detalle != 1)"
          @click="eliminar()"
        >
          <v-list-item-title>
            <v-icon :disabled="!(elimina && permiso && item_selected.sin_detalle != 1)" color="error" small left>fas fa-trash</v-icon>
            Eliminar
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- COMPONENTES -->
    <DescargarLiquidaciones
      v-model="dialog_descargar"
      :p_periodo="filtro.periodo"
      :p_familia="filtro.familia"
    />
    <DetalleCartera
      v-model="dialog_detalle"
      :item="item_selected"
    />
    <AMLiquidacionDetalle
      v-model="dialog_am"
      :item="item_selected"
      @nueva="agregar"
      @editar="editar"
    />
    <NuevaLiquidacion
      v-model="dialog_nueva"
    />
    <InformeLiquidacion
      v-model="dialog_descargar_pdf"
      :periodo="filtro.periodo"
      :familia="filtro.familia"
    />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { format_money } from '../../util/utils'
import moment from 'moment'
import SearchDataTable from '../../components/util/SearchDataTable'
import PeriodoPicker from '../../components/util/PeriodoPicker'
import BtnFiltro from '../../components/util/BtnFiltro'
import DescargarLiquidaciones from '../../components/pa/DescargarLiquidaciones'
import DetalleCartera from '../../components/pa/DetalleCartera'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import AMLiquidacionDetalle from '../../components/pa/AMLiquidacionDetalle'
import NuevaLiquidacion from '../../components/pa/NuevaLiquidacion'
import InformeLiquidacion from '../../components/pa/InformeLiquidacion'

export default {
  data () {
    return {
      formatMoney: format_money,
      x: 0,
      y: 0,
      panel: 0,
      total: 0,
      selected_id: null, // esta variable se utiliza para marcar de negrita la fila seleccionada con click derecho
      liquidacion_id: null,
      load: false,
      permiso: false,
      elimina: false,
      showMenu: false,
      dialog_am: false,
      dialog_nueva: false,
      expandir_todos: false,
      dialog_detalle: false,
      dialog_descargar: false,
      dialog_descargar_pdf: false,
      search: '',
      headers: [
        { text: 'Supervisor', value: 'supervisor' },
        { text: 'Vendedor', value: 'vendedor' },
        { text: 'Total', value: 'total', align: 'right', formatter: format_money }
      ],
      headers_detalle: [
        { text: 'Suscripción', value: 'preimpreso', align: 'right' },
        { text: 'Grupo/Orden', value: 'grupo_orden' },
        { text: 'Marca', value: 'empresa_nombre' },
        { text: 'Provincia', value: 'provincia' },
        { text: 'Ingreso', value: 'fecha_ingreso' },
        { text: 'Titular', value: 'titular' },
        { text: 'Inaugurado', value: 'inaugurado' },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Concepto', value: 'concepto_nombre' },
        { text: 'Porc.', value: 'porcentaje', align: 'right' },
        { text: 'Importe', value: 'importe', align: 'right', formatter: format_money },
        { text: 'Débito Aut.', value: 'debito', align: 'center' },
        { text: 'Cuota Pag/Ven', value: 'pagada_vencida' },
        { text: 'Obsv.', value: 'comentario', align: 'center' }
      ],
      liquidaciones: [],
      cabecera: [],
      expanded: [],
      item_selected: {},
      filtro: {
        periodo: moment().startOf('month').subtract(1, 'month').format('MM/YYYY'),
        familia: null,
        empresa: null,
        supervisor: null,
        vendedor: null,
        concepto: null,
        titular: '',
        modelo: '',
        preimpreso: ''
      }
    }
  },
  async created () {
    // si tiene solo 1 familia la selecciono
    if (this.una_familia) this.filtro.familia = this.familias[0].id
    // si tiene solo 1 empresa la selecciono
    if (this.una_empresa) this.filtro.empresa = this.empresas[0].id
    this.$store.state.loading = true
    await this.$store.dispatch('genericos/get_vendedores')
    await this.$store.dispatch('genericos/get_supervisores')
    await this.$store.dispatch('genericos/get_conceptos')
    this.permiso = await this.$store.dispatch('comisiones/tiene_permiso')
    this.$store.state.loading = false
  },
  computed: {
    ...mapGetters(['una_empresa', 'una_familia']),
    ...mapState(['empresas', 'familias']),
    ...mapState('genericos', ['vendedores', 'supervisores', 'conceptos']),
  },
  components: {
    SearchDataTable,
    PeriodoPicker,
    BtnFiltro,
    DescargarLiquidaciones,
    DetalleCartera,
    BtnConfirmar,
    AMLiquidacionDetalle,
    NuevaLiquidacion,
    InformeLiquidacion
  },
  watch: {
    showMenu (val) {
      val || (this.selected_id = null)
    },
    'filtro.periodo': {
      handler: function () {
        this.limpiar_liqudiacion()
      },
      deep: true
    },
    'filtro.familia': {
      handler: function () {
        this.limpiar_liqudiacion()
      },
      deep: true
    }
  },
  methods: {
    async eliminar_liquidacion () {
      this.$swal.fire({
        html: `¿Desea <strong>eliminar</strong> la liquidación del periodo <strong>${this.filtro.periodo}</strong>?`,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        showCancelButton: true,
        allowEnterKey: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$store.state.loading = true
          await this.$store.dispatch('comisiones/eliminar_liquidacion', {
            periodo: this.filtro.periodo,
            id: this.liquidacion_id
          })
            .then((res) => {
              this.limpiar_liqudiacion()
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
      })
    },
    async agregar (item) {
      this.liquidaciones.push(item)
      this.actualizarCabecera(item.liq_vend_id, item.total)
      this.total = item.total_liq
    },
    async editar (item) {
      let liqui = this.liquidaciones.find(l => l.id == this.item_selected.id)
      liqui.concepto = item.concepto
      liqui.concepto_nombre = this.conceptos.find(c => c.id == item.concepto).nombre
      liqui.porcentaje = item.porcentaje / 100
      liqui.importe = item.importe
      liqui.comentario = item.comentario
      this.actualizarCabecera(this.item_selected.liq_vend_id, item.total)
      this.total = item.total_liq
    },
    async eliminar () {
      this.$swal.fire({
        html: `¿Desea <strong>eliminar</strong> el concepto <strong>${this.item_selected.concepto_nombre}</strong> de la suscripción <strong>${this.item_selected.preimpreso}</strong>?`,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        showCancelButton: true,
        allowEnterKey: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$store.state.loading = true
          await this.$store.dispatch('comisiones/eliminar', this.item_selected.id)
            .then((res) => {
              const index = this.liquidaciones.indexOf(this.liquidaciones.find(l => l.id == this.item_selected.id))
              this.liquidaciones.splice(index, 1)
              this.actualizarCabecera(this.item_selected.liq_vend_id, res.total)
              this.total = res.total_liq
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
      })
    },
    async buscar () {
      if (!this.filtro.familia) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'orange'
        })
      }
      this.expanded = []
      this.cabecera = []
      this.liquidaciones = []
      this.item_selected = {}
      this.liquidacion_id = null
      this.expandir_todos = false
      this.elimina = false
      this.search = ''
      this.load = true
      await this.$store.dispatch('comisiones/get_liquidaciones', this.filtro)
        .then((res) => {
          this.liquidaciones = res.data
          this.cabecera = res.cabecera
          this.elimina = res.elimina
          this.total = res.total
          this.liquidacion_id = res.liq_id
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    click_derecho (event, item) {
      event.preventDefault()
      this.showMenu = false
      this.x = event.clientX
      this.y = event.clientY
      this.$nextTick(() => {
        this.showMenu = true
        this.item_selected = JSON.parse(JSON.stringify(item.item))
        this.selected_id = JSON.parse(JSON.stringify(item.item.id))
      })
    },
    actualizarCabecera (liq_vend_id, total) {
      let cabecera = this.cabecera.find(c => c.id == liq_vend_id)
      cabecera.total = total
    },
    sumarTotal (array, sin_formato) {
      let total = 0
      array.forEach(detalle => {
        total += detalle.importe
      })
      if (sin_formato == 1) return total
      else return format_money(total)
    },
    itemRowBackground (item) {
      return this.expanded.find(e => e.id == item.id) ? ['background-color: rowsel', 'font-weight-bold'] : ''
    },
    itemRowBackgroundDetalle (item) {
      return item.id == this.selected_id ? ['font-weight-bold'] : ''
    },
    //@click:row="rowClick"
    rowClick (item, row) {
      let expandedState = (row.isExpanded) ? false : true
      row.expand(expandedState)
    },
    expandir () {
      this.expandir_todos = true
      this.expanded = JSON.parse(JSON.stringify(this.cabecera))
    },
    contraer () {
      this.expandir_todos = false
      this.expanded = []
    },
    limpiar_liqudiacion () {
      this.item_selected = {}
      this.liquidaciones = []
      this.cabecera = []
      this.expanded = []
      this.search = ''
      this.liquidacion_id = null
      this.expandir_todos = false
      this.elimina = false
      this.total = 0
    },
    async limpiar () {
      this.filtro.familia = this.una_familia ? this.familias[0].id : null
      this.filtro.empresa = null
      this.filtro.supervisor = null
      this.filtro.vendedor = null
      this.filtro.concepto = null
      this.filtro.titular = ''
      this.filtro.modelo = ''
      this.filtro.preimpreso = ''
    }
  }
}
</script>