<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nuevo Modelo"
              @click="abrirModalNuevo"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-btn
              style="
                position: absolute;
                top: -15px;
                right: 105px;
                z-index: 1;
                color: white;
              "
              color="blue"
              small
              fab
              title="Modelos"
              @click="abrirModalModelos"
            >
              <v-icon>fas fa-car</v-icon>
            </v-btn>

            <v-expansion-panel-header
              class="py-0 px-4 text-h5"
              style="cursor: default"
            >
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>

            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Empresa
                    <v-autocomplete
                      @change="setMarcas"
                      v-model="filtro.empresa"
                      item-text="nombre"
                      return-object
                      :items="familias"
                      hide-details
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>

                  <!-- MARCAS -->
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Marcas
                    <v-autocomplete
                      @change="setModelos"
                      v-model="filtro.marca"
                      item-text="nombre"
                      item-value="id"
                      tabindex="1"
                      :items="
                        marcas.filter(
                          filtro.empresa.id
                            ? marca => marca.familia == filtro.empresa.id
                            : marca => !marca
                        )
                      "
                      hide-details
                      outlined
                      dense
                      chips
                      clearable
                      deletable-chips
                      small-chips
                      multiple
                    ></v-autocomplete>
                  </v-col>

                  <!-- MODELOS -->
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Modelos
                    <v-autocomplete
                      v-model="filtro.modelo"
                      item-text="Modelo"
                      item-value="Modelo"
                      tabindex="1"
                      :items="modelosFiltrados"
                      hide-details
                      outlined
                      dense
                      chips
                      clearable
                      deletable-chips
                      small-chips
                      multiple
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="0" sm="2" md="2" class="py-1"> </v-col>

                  <!-- FECHA DESDE -->

                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Fecha desde
                    <FechaPicker v-model="filtro.desde" />
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Fecha hasta
                    <FechaPicker v-model="filtro.hasta" />
                  </v-col>

                  <v-col cols="12" sm="2" md="6" class="pt-0">
                    <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div
          style="
            width: 100%;
            margin-top: 0.6rem;
            display: flex;
            justify-content: end;
            gap: 1rem;
          "
        >
          <v-btn class="" color="blue" dark @click="subir()">
            <v-icon left> fas fa-file-upload </v-icon>
            Subir
          </v-btn>

          <v-btn class="" color="green" dark @click="descargar()">
            <v-icon left> fas fa-file-download </v-icon>
            Descargar
          </v-btn>
        </div>

        <v-data-table
          class="cebra elevation-2 mt-2"
          item-key="id"
          sort-by="id"
          :headers="headers"
          :items="preciosModelosFiltrados"
          :loading="load"
          sort-desc
          dense
        >
          <template
            v-for="header in headers.filter(header =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:[`item.valor_venta`]="{ item }">
            {{ item.valor_venta | formatMoney }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div
              style="
                display: flex;
                gap: 3px;
                align-items: center;
                justify-content: start;
              "
            >
              <v-icon
                color="success"
                title="Editar"
                class="mr-2"
                small
                @click="abrir_modal_editar(item)"
              >
                fas fa-pen
              </v-icon>
              <BtnConfirmar
                :disabled="item.botonEliminar"
                icono="fas fa-trash"
                color="error"
                title="Eliminar"
                :texto="`¿Desea <strong>eliminar</strong> el precio - modelo <strong>${item.Descripcion}</strong>?`"
                :icon_button="true"
                :small="true"
                @action="eliminar(item)"
              />
            </div>
          </template>
        </v-data-table>

        <!-- SHEET -->
        <v-bottom-sheet v-model="sheet">
          <v-sheet class="text-center" height="auto">
            <div
              style="
                display: flex;
                flex-direction: column;
                gap: 1;
                padding: 0 12px;
              "
            >
              <v-btn
                style="align-self: flex-end"
                class=""
                icon
                @click="sheet = false"
              >
                <v-icon> fas fa-times </v-icon>
              </v-btn>
              <div class="mb-2">
                <FileDropzone
                  :files.sync="archivo"
                  :tipos="tipos_validos"
                  :multiple="false"
                  :max_size="25"
                />
              </div>
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </v-col>
    </v-row>
    <NuevoPrecioModelo
      @precioGuardado="precioGuardado"
      v-model="modalNuevo"
      :modelos="modelos"
      :precios="preciosModelosSeteados"
    />
    <ModalEditarPrecioModelo
      @editar="editarItem"
      v-model="modalEditar"
      :precio="precio"
    />
    <ModalModelos v-model="modalModelos" />
  </div>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import { mapState } from 'vuex'
import FechaPicker from '../../components/util/FechaPicker.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import NuevoPrecioModelo from '../../components/pa/NuevoPrecioModelo.vue'
import ModalEditarPrecioModelo from '../../components/pa/ModalEditarPrecioModelo.vue'
import moment from 'moment'
import { format_money_round } from '../../util/utils'
import FileDropzone from '../../components/util/FileDropzone.vue'
import FileSaver from 'file-saver'
import { read, utils, write } from 'xlsx'
import ModalModelos from '../../components/pa/ModalModelos.vue'

export default {
  data() {
    return {
      panel: 0,
      load: false,
      sheet: false,
      modalNuevo: false,
      modalEditar: false,
      modalModelos: false,
      archivo: [],
      tipos_validos: ['.xls', '.xlsx', '.ods'],
      empresas: [],
      marcas: [],
      preciosModelos: [],
      modelos: [],
      precio: {},
      modelosSeteados: [],
      modelosModal: [],
      modelosFiltrados: [],
      preciosModelosSeteados: [],
      preciosModelosFiltrados: [],
      preciosModelosModificados: [],
      filtro: {
        empresa: {},
        marca: [],
        modelo: [],
        desde: '',
        hasta: moment().format('DD/MM/YYYY')
      },
      headers: [
        { text: 'Empresa', value: 'empresa_nombre' },
        { text: 'Marca', value: 'marca' },
        { text: 'Cod. Modelo', value: 'Modelo' },
        { text: 'Modelo', value: 'Descripcion' },
        {
          text: 'Desde',
          value: 'fecha_desde_date',
          formatter: this.formatDate
        },
        {
          text: 'Hasta',
          value: 'fecha_hasta_date',
          formatter: this.formatDate
        },
        { text: 'Precio', value: 'valor_venta', aling: 'end' },
        { text: 'Acciones', value: 'actions', sortable: false }
      ]
    }
  },

  created() {
    this.marcas = this.$store.state.empresas
    this.getModelos()
    if (this.familias.length == 1) {
      this.filtro.empresa = this.familias[0].id
      this.setMarcas()
    }
  },

  methods: {
    async getPreciosModelos(payload) {
      await this.$store
        .dispatch('preciosModelos/getPreciosModelos', payload)
        .then(res => {
          res.exito == 1 ? (this.preciosModelos = res.data) : null

          this.preciosModelosSeteados = structuredClone(this.preciosModelos)

          this.preciosModelosSeteados.forEach(precio => {
            precio.fecha_desde_date = precio.fecha_desde
            precio.fecha_hasta_date = precio.fecha_hasta

            precio.fecha_desde = moment
              .utc(precio.fecha_desde)
              .format('DD/MM/YYYY')
            precio.fecha_hasta = moment
              .utc(precio.fecha_hasta)
              .format('DD/MM/YYYY')
            precio.botonEliminar = true
            precio.empresa_nombre = this.familias.find(
              familia =>
                familia.id ===
                this.marcas.find(marca => marca.id == precio.Empresa_id)
                  ?.familia
            )?.nombre
            precio.marca = this.marcas.find(
              marca => marca.id == precio.Empresa_id
            )?.nombre
          })

          const modelosAgrupados = this.preciosModelosSeteados.reduce(
            (acc, precio) => {
              if (!acc[precio.Modelo_id]) {
                acc[precio.Modelo_id] = []
              }
              acc[precio.Modelo_id].push(precio)
              return acc
            },
            {}
          )

          Object.values(modelosAgrupados).forEach(modelos => {
            modelos.sort(
              (a, b) =>
                moment(b.fecha_desde, 'DD/MM/YYYY').valueOf() -
                moment(a.fecha_desde, 'DD/MM/YYYY').valueOf()
            )

            if (modelos.length > 0) {
              this.preciosModelosSeteados.forEach(precio => {
                if (precio.precio_id == modelos[0].precio_id) {
                  precio.botonEliminar = false
                }
              })
            }
          })

          this.preciosModelosSeteados.sort(
            (a, b) =>
              moment(b.fecha_desde, 'DD/MM/YYYY').valueOf() -
              moment(a.fecha_desde, 'DD/MM/YYYY').valueOf()
          )
        })
    },

    async getModelos() {
      this.$store.state.loading = true
      await this.$store
        .dispatch('preciosModelos/getModelos')
        .then(res => {
          res.exito == 1 ? (this.modelos = res.data) : null
          this.modelos = this.modelos.sort((a, b) =>
            a.Descripcion.localeCompare(b.Descripcion)
          )
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            color: 'error',
            text: error
          })
        })
      this.$store.state.loading = false
    },

    async abrirModalNuevo() {
      if (
        Object.keys(this.filtro.empresa).length > 0 &&
        this.filtro.marca.length > 0
      ) {
        this.modalNuevo = true
      } else {
        this.$store.dispatch('show_snackbar', {
          color: 'orange',
          text: 'Debe seleccionar una empresa y marca'
        })
      }
    },

    async getModelosFiltrados() {
      await this.getPreciosModelos(this.filtro.marca)
      await this.getModelos()

      const preciosMap = this.preciosModelosSeteados.map(
        precio => precio.Modelo_id
      )

      this.modelosSeteados = this.modelos.filter(modelo =>
        preciosMap.includes(modelo.Modelo_id)
      )
      this.modelosFiltrados = this.modelosSeteados
    },

    async buscar() {
      this.preciosModelosSeteados = []
      if (
        Object.keys(this.filtro.empresa).length == 0 ||
        this.filtro.marca.length == 0
      ) {
        this.$store.dispatch('show_snackbar', {
          color: 'orange',
          text: 'Debe seleccionar una empresa y marca'
        })
      } else {
        this.$store.state.loading = true

        await this.getPreciosModelos(this.filtro.marca)
        this.$store.state.loading = false
        let fechaHasta = moment(this.filtro.hasta, 'DD/MM/YYYY')
        let fechaDesde = moment(this.filtro.desde, 'DD/MM/YYYY')
        let preciosModelos = null

        if (this.filtro.desde.length == 0) {
          preciosModelos = this.preciosModelosSeteados.filter(precio =>
            moment(precio.fecha_hasta, 'DD/MM/YYYY').isSameOrBefore(fechaHasta)
          )
          this.preciosModelosFiltrados = preciosModelos
        } else {
          this.preciosModelosFiltrados = this.preciosModelosSeteados.filter(
            precio => {
              let precio_desde_cleaned = precio.fecha_desde.trim()
              let precio_hasta_cleaned = precio.fecha_hasta.trim()

              let precio_desde = moment(
                precio_desde_cleaned,
                'DD/MM/YYYY',
                true
              )
              let precio_hasta = moment(
                precio_hasta_cleaned,
                'DD/MM/YYYY',
                true
              )

              let dentroDelRango =
                precio_desde.isSameOrAfter(fechaDesde) &&
                precio_desde.isSameOrBefore(fechaHasta)

              return dentroDelRango
            }
          )
        }

        // MODELO
        if (this.filtro.modelo.length > 0) {
          this.preciosModelosFiltrados = this.preciosModelosSeteados.filter(
            precio => this.filtro.modelo.includes(precio.Modelo)
          )
        }
      }
    },

    setMarcas() {
      if (this.filtro.empresa.id) {
        this.filtro.marca = []

        let marca = this.marcas.filter(
          marca => marca.familia == this.filtro.empresa.id
        )
        marca.length == 1 ? this.filtro.marca.push(marca[0].id) : null
      }
      if (this.filtro.marca > 0) {
        this.setModelos()
      }
    },

    async setModelos() {
      if (this.filtro.marca.length > 0) {
        await this.getModelosFiltrados()
        this.modelosFiltrados = this.modelosSeteados.filter(modelo =>
          this.filtro.marca.includes(modelo.Empresa_id)
        )
      } else {
        this.modelosFiltrados = []
      }
    },

    limpiarFiltro() {
      this.filtro.empresa = {}
      this.filtro.marca = []
      this.filtro.modelo = []
      this.filtro.desde = ''
      this.filtro.hasta = moment().format('DD/MM/YYYY')

      this.modelosFiltrados = []
    },

    subir() {
      if (
        Object.keys(this.filtro.empresa).length > 0 &&
        this.filtro.marca.length > 0
      ) {
        this.sheet = true
        if (this.archivo.length > 0) {
          this.dialogPublicar = true
        }
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Selecciona empresa y marca.',
          color: 'warning'
        })
      }
    },
    descargar() {
      if (this.preciosModelosFiltrados.length > 0) {
        const cabecera = [['Cod. Modelo', 'Modelo', 'Desde', 'Hasta', 'Precio']]

        const ws = utils.aoa_to_sheet(cabecera)
        const array = []

        this.preciosModelosFiltrados.forEach(precio => {
          let data = {
            codModelo: precio.Modelo,
            Descripcion: precio.Descripcion,
            fecha_desde: precio.fecha_desde,
            fecha_hasta: precio.fecha_hasta,
            valor_venta: precio.valor_venta
          }

          array.push(data)
        })

        utils.sheet_add_json(ws, array, {
          header: [
            'codModelo',
            'Descripcion',
            'fecha_desde',
            'fecha_hasta',
            'valor_venta'
          ],
          skipHeader: true,
          origin: -1
        })

        // Ajusto ancho de columnas automáticamente
        const columnas = Object.keys(array[0])
        columnas.forEach(columna => {
          const maxLength = array.reduce(
            (max, row) => Math.max(max, String(row[columna]).length),
            0
          )
          ws['!cols'] = ws['!cols'] || []
          ws['!cols'].push({ wch: maxLength + 2 })
        })

        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Reporte')
        const wbout = write(wb, { bookType: 'xlsx', type: 'array' })
        let name = 'Precios_modelos_' + moment().format('DD/MM/YYYY') + '.xlsx'
        FileSaver.saveAs(
          new Blob([wbout], { type: 'application/octet-stream' }),
          name
        )
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes filtrar en la tabla antes de descargar el reporte.',
          color: 'warning'
        })
      }
    },

    abrir_modal_editar(item) {
      this.precio = item
      this.modalEditar = true
    },

    precioGuardado(data) {
      if (data) {
        this.filtro.hasta = data.hasta
        this.filtro.desde = data.desde
        this.buscar()
      }
    },

    async eliminar(item) {
      await this.$store
        .dispatch('preciosModelos/eliminarPrecioModelo', item)
        .then(res => {
          if (res.exito) {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
            this.preciosModelosFiltrados = this.preciosModelosFiltrados.filter(
              precio => precio.precio_id != item.precio_id
            )
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
    },

    editarItem(item) {
      this.preciosModelosFiltrados.forEach(precio => {
        if (precio.precio_id === item.precio_id) {
          precio.fecha_desde_date = moment(item.desde, 'DD/MM/YYYY').toDate();
          precio.fecha_hasta_date = moment(item.hasta, 'DD/MM/YYYY').toDate()

          precio.fecha_desde = item.desde
          precio.fecha_hasta = item.hasta
          precio.Modelo = item.modelo
          precio.valor_venta = item.precio
        }
      })
    },

    abrirModalModelos() {
      this.modalModelos = true
    },

    formatDate(date, format) {
      if (!date) return ''
      return moment.utc(date).format(format ? format : 'DD/MM/YYYY')
    }
  },

  watch: {
    'filtro.empresa': val => {
      if (!val) {
        this.filtro.marca = []
      }
    },

    async archivo() {
      if (this.archivo.length > 0) {
        const reader = new FileReader()
        reader.onload = async e => {
          const data = new Uint8Array(e.target.result)
          const workbook = read(data, { type: 'array' })
          const sheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[sheetName]
          const jsonData = utils.sheet_to_json(worksheet, { raw: true })

          this.preciosModelosModificados = jsonData.map(precio => {
            let {
              'Cod. Modelo': Modelo,
              Modelo: Descripcion,
              Desde: fecha_desde,
              Hasta: fecha_hasta,
              Precio: valor_venta,
              ...props
            } = precio

            if (typeof valor_venta == 'string') {
              Number(valor_venta)
            }
            return {
              ...props,
              Modelo,
              Descripcion,
              fecha_desde,
              fecha_hasta,
              valor_venta
            }
          })

          const arrayDeModelosModificados = this.preciosModelosModificados.map(
            precio => precio.Modelo
          )
          const matchPreciosModelos = this.preciosModelosSeteados.filter(
            precioMatch =>
              arrayDeModelosModificados.includes(precioMatch.Modelo)
          )

          const preciosModelosParaEnviar =
            this.preciosModelosModificados.filter(precio => {
              const arrayDeCadaMatch = matchPreciosModelos.filter(
                precioMatch => {
                  return precio.Modelo == precioMatch.Modelo
                }
              )

              if (arrayDeCadaMatch.length > 0) {
                let fechaDesde = moment(precio.fecha_desde, 'DD/MM/YYYY')
                let fechaHasta = moment(precio.fecha_hasta, 'DD/MM/YYYY')

                let periodoExiste = arrayDeCadaMatch.some(precioCadaMatch => {
                  const fechaPrecioDesde = moment(
                    precioCadaMatch.fecha_desde,
                    'DD/MM/YYYY'
                  )
                  const fechaPrecioHasta = moment(
                    precioCadaMatch.fecha_hasta,
                    'DD/MM/YYYY'
                  )

                  return (
                    (fechaDesde.isSameOrAfter(fechaPrecioDesde) &&
                      fechaDesde.isSameOrBefore(fechaPrecioHasta)) ||
                    (fechaHasta.isSameOrAfter(fechaPrecioDesde) &&
                      fechaHasta.isSameOrBefore(fechaPrecioHasta)) ||
                    (fechaDesde.isBefore(fechaPrecioDesde) &&
                      fechaHasta.isAfter(fechaPrecioHasta))
                  )
                })

                return !periodoExiste
              } else {
                return true
              }
            })

          this.buscar()
          if (preciosModelosParaEnviar.length > 0) {
            this.$store.state.loading = true

            await this.$store
              .dispatch(
                'preciosModelos/postPreciosMasivo',
                preciosModelosParaEnviar
              )
              .then(res => {
                if (res.exito) {
                  this.sheet = false
                  this.$swal.fire({
                    icon: 'success',
                    title: 'Los precios se insertaron correctamente'
                  })
                  this.filtro.desde = preciosModelosParaEnviar[0].fecha_desde
                  this.filtro.hasta = preciosModelosParaEnviar[0].fecha_hasta

                  this.buscar()
                  this.archivo = []
                } else {
                  this.$store.dispatch('show_snackbar', {
                    color: 'error',
                    text: res.message
                  })
                }
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  color: 'error',
                  text: error.message
                })
              })
            this.$store.state.loading = false
          } else {
            this.$store.dispatch('show_snackbar', {
              color: 'warning',
              text: 'No hay precios modelos para actualizar o los períodos ya existen.'
            })
          }
        }
        reader.readAsArrayBuffer(this.archivo[0])
      }
    }
  },

  filters: {
    formatMoney(value) {
      return format_money_round(value)
    }
  },

  computed: {
    ...mapState(['familias'])
  },

  components: {
    BtnFiltro,
    FechaPicker,
    BtnConfirmar,
    FileDropzone,
    NuevoPrecioModelo,
    ModalEditarPrecioModelo,
    ModalModelos
  }
}
</script>
