<template>
  <div>
    <v-dialog v-model="dialog" max-width="780" scrollable>
      <v-card>
        <v-card-title class="d-flex mt-0 mx-md-2 mx-n1 mb-0">
          Editar Precio - Modelo
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" sm="4" md="4" class="py-1">
              Codigo Modelo
              <v-text-field v-model.trim="data.modelo" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12" sm="5" md="5" class="py-1">
              Modelo
              <v-text-field v-model.trim="data.descripcion" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-1">
              Fecha desde
              <FechaPicker v-model="data.desde" hide-details />
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-1">
              Fecha hasta
              <FechaPicker v-model="data.hasta" hide-details />
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-1">
              Precio
              <TextFieldMoney v-model="data.precio" type="number" v-bind:properties="{
                prefix: '$',
                outlined: true,
                dense: true,
              }" />
            </v-col>
          </v-row>
          <div style="display: flex; justify-content: end">
            <v-btn class="mr-2" color="secondary" @click="dialog = !dialog">
              Volver
            </v-btn>
            <BtnConfirmar @action="editarPrecio()" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FechaPicker from "../util/FechaPicker.vue";
import BtnConfirmar from "../util/BtnConfirmar.vue";
import TextFieldMoney from "../../components/util/TextFieldMoney.vue";

export default {
  data() {
    return {
      data: {
        modelo: null,
        descripcion: '',
        desde: '',
        hasta: '',
        precio: null,
        precio_id: null,
        modelo_id: null
      },
      itemEditar: null,

      precioInicial: {},
      marcas: null,
    };
  },

  methods: {

    async editarPrecio() {

      this.$store.dispatch('preciosModelos/editarPrecioModelo', this.data).then(res => {

        if (res.exito) {
          this.itemEditar = res.data

          this.$store.state.loading = false
          this.$swal.fire({
            icon: "success",
            title: res.message,
          });

          this.$emit('editar', this.itemEditar)
        } else {
          this.$store.state.loading = false
          this.$store.dispatch("show_snackbar", {
            text: res.message,
            color: "error",
          });
        }

        this.dialog = false;
      })
    },
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState(["familias", "empresas"]),
  },

  watch: {
    dialog(value) {

      if (!value) {
        this.data.modelo = null;
        this.data.desde = "";
        this.data.hasta = "";
        this.data.precio = null;
        this.data.precio_id = null
        this.data.modelo_id = null
        this.data.descripcion = ""
      } else {
        this.data.modelo = this.precio.Modelo
        this.data.desde = this.precio.fecha_desde
        this.data.hasta = this.precio.fecha_hasta
        this.data.precio = this.precio.valor_venta
        this.data.precio_id = this.precio.precio_id
        this.data.modelo_id = this.precio.Modelo_id
        this.data.descripcion = this.precio.Descripcion
      }
    },
  },

  props: {
    value: Boolean,
    precio: Object,
  },

  components: {
    FechaPicker,
    BtnConfirmar,
    TextFieldMoney,
  },
};
</script>