<template>
  <v-dialog
    v-model="dialog"
    width="400"
  >
    <v-card>
      <v-card-title>
        Descargar liquidación
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-0">
          <v-col>
            Empresa
            <v-autocomplete
              v-model="familia"
              item-value="id"
              item-text="nombre"
              :items="familias"
              hide-details
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col>
            Periodo
            <PeriodoPicker
              v-model="periodo"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          @click="descargar()"
        >
          <v-icon left>fas fa-download</v-icon>
          Descargar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { parseDate } from '../../util/utils'
import moment from 'moment'
import download from 'downloadjs'
import PeriodoPicker from '../util/PeriodoPicker'

export default {
  data () {
    return {
      periodo: null,
      familia: null
    }
  },
  props: {
    value: Boolean,
    p_periodo: String,
    p_familia: Number
  },
  computed: {
    ...mapState(['familias']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    PeriodoPicker
  },
  watch: {
    dialog (val) {
      if (val) {
        this.periodo = JSON.parse(JSON.stringify(this.p_periodo))
        this.familia = JSON.parse(JSON.stringify(this.p_familia))
      } else {
        this.periodo = null
        this.familia = null
      }
    }
  },
  methods: {
    async descargar () {
      if (!this.periodo || !this.familia) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un periodo y una empresa',
          color: 'orange'
        })
      }
      const periodo = this.periodo
      const familia = this.familia
      const nombre = this.familias.find(fa => fa.id == familia).nombre

      this.dialog = false
      this.$store.state.loading = true
      await this.$store.dispatch('comisiones/get_excel', {
        periodo: periodo,
        familia: familia
      })
        .then((res) => {
          download(res.data, `Liquidación ${moment(parseDate(`01/${periodo}`)).format('MM-YYYY')} - ${nombre}.xls`, 'application/vnd.ms-excel')
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    }
  }
}
</script>