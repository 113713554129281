<template>
  <v-dialog
    v-model="dialog"
    width="950"
    :persistent="load"
    scrollable
  >
    <v-card
      :loading="load"
      :disabled="load"
    >
      <v-card-title>
        Conceptos liquidados {{ item.preimpreso }} {{ item.grupo == '' ? '' : ` - ${item.grupo}/${item.orden}` }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="load">
        <v-skeleton-loader
          class="mx-auto"
          type="list-item-three-line"
        ></v-skeleton-loader>
      </v-card-text>
      <v-card-text v-else class="pa-0">
        <v-row class="mx-4 my-2" style="font-size: 14px" no-gutters>
          <v-col cols="12" md="6" class="mb-1">
            Titular
            <strong class="ml-4">
              {{ item.titular }}
            </strong>
          </v-col>
          <v-col cols="12" md="2" class="d-flex justify-center">
            <div v-if="item.pagada_vencida && item.grupo != ''">
              Cuotas pag./ven.
              <strong>{{ item.pagada_vencida }}</strong>
            </div>
          </v-col>
          <v-col cols="12" md="4" class="d-flex justify-md-end mb-1">
            Fecha de ingreso
            <strong style="margin-left: 6px">
              {{ item.fecha_ingreso }}
            </strong>
          </v-col>
          <v-col cols="12" md="8">
            Vehículo
            <strong style="margin-left: 2px">
              {{ item.vehiculo ? item.vehiculo : item.descripcion }}
            </strong>
          </v-col>
          <v-col v-if="item.inaugurado" cols="12" md="4" class="d-flex justify-md-end mb-1">
            Inaugurado
            <strong style="margin-left: 6px">
              {{ item.inaugurado }}
            </strong>
          </v-col>
        </v-row>
        <v-data-table
          class="cebra"
          sort-by="liquidacion_id"
          :headers="headers"
          :items="detalle"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <!-- template para formatear montos -->
          <template
            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:[`item.porcentaje`]="{ item }">
            {{ (item.porcentaje * 100).toFixed(1) }} %
          </template>
          <!-- row de totales -->
          <template slot="body.append">
            <tr>
              <th style="font-size: 14px">Totales</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th style="font-size: 14px" class="text-right">
                {{ porcentaje_total.toFixed(1) }} %
              </th>
              <th style="font-size: 14px" class="text-right">
                {{ formatMoney(importe_total) }}
              </th>
            </tr>
          </template>
          <!-- no data -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron detalles
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format_money } from '../../util/utils'
import moment from 'moment'

export default {
  data () {
    return {
      moment: moment,
      formatMoney: format_money,
      load: false,
      porcentaje_total: 0,
      importe_total: 0,
      headers: [
        { text: 'Liquidación ID', value: 'liquidacion_id', align: 'right' },
        { text: 'Periodo', value: 'periodo' },
        { text: 'Vendedor', value: 'vendedor' },
        { text: 'Concepto', value: 'concepto' },
        { text: 'Valor Vehículo', value: 'valor_vehiculo', align: 'right', formatter: format_money },
        { text: 'Porcentaje', value: 'porcentaje', align: 'right' },
        { text: 'Importe', value: 'importe', align: 'right', formatter: format_money }
      ],
      detalle: []
    }
  },
  props: {
    value: Boolean,
    item: Object
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.load = true
        await this.$store.dispatch('cartera/get_detalle', {
          empresa: this.item.empresa,
          preimpreso: this.item.preimpreso,
          vendedor: this.item.vendedor
        })
          .then((res) => {
            this.detalle = res.data
            this.calcular_totales()
          })
          .catch(error => {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          })
        this.load = false
      } else {
        this.detalle = []
        this.porcentaje_total = 0
        this.importe_total = 0
      }
    }
  },
  methods: {
    calcular_totales () {
      this.detalle.forEach(detalle => {
        this.porcentaje_total += parseFloat(detalle.porcentaje)
        this.importe_total += parseFloat(detalle.importe)
      })
      this.porcentaje_total *= 100
    }
  }
}
</script>