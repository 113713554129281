<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :class="$vuetify.breakpoint.xs ? 'mt-0' : ''">
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Periodo
                  <PeriodoPicker
                    v-model="filtro.periodo"
                    :clearable="true"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.familia"
                    item-value="id"
                    item-text="nombre"
                    :items="familias"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.empresa = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Marca
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-value="id"
                    item-text="nombre"
                    :items="empresas.filter(emp => emp.familia == filtro.familia)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="py-1">
                  Suscripción
                  <v-text-field
                    v-model.trim="filtro.preimpreso"
                    tabindex="1"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Grupo
                  <v-text-field
                    v-model.trim="filtro.grupo"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Orden
                  <v-text-field
                    v-model.trim="filtro.orden"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" xl="2" class="py-1">
                  Titular
                  <v-text-field
                    v-model.trim="filtro.titular"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  :clase="$vuetify.breakpoint.xs ? 'mt-0' : undefined"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="preimpreso"
        :headers="headers"
        :items="carteras"
        :loading="load"
        :search="search"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- template para formatear montos -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Detalle"
            class="mr-2"
            small
            @click="cartera = item; dialog_detalle = true"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            color="purple"
            title="¿Tiene recibo?"
            small
            @click="tiene_recibo(item)"
          >
            fas fa-receipt
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <DetalleCartera
      v-model="dialog_detalle"
      :item="cartera"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { format_money } from '../../util/utils'
import SearchDataTable from '../../components/util/SearchDataTable'
import PeriodoPicker from '../../components/util/PeriodoPicker'
import BtnFiltro from '../../components/util/BtnFiltro'
import DetalleCartera from '../../components/pa/DetalleCartera'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      dialog_detalle: false,
      search: '',
      headers: [
        { text: 'Suscripción', value: 'preimpreso', align: 'right' },
        { text: 'Grupo', value: 'grupo' },
        { text: 'Orden', value: 'orden' },
        { text: 'Fecha Ingreso', value: 'fecha_ingreso' },
        { text: 'Titular', value: 'titular' },
        { text: 'Vendedor', value: 'vendedor_nombre' },
        { text: 'Vehículo', value: 'vehiculo' },
        { text: 'Total vehículo', value: 'total_vehiculo', align: 'right', formatter: format_money },
        { text: 'Marca', value: 'empresa_nombre' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      carteras: [],
      cartera: {},
      filtro: {
        periodo: null,
        familia: null,
        empresa: null,
        preimpreso: '',
        grupo: '',
        orden: '',
        titular: ''
      }
    }
  },
  computed: {
    ...mapState(['empresas', 'familias'])
  },
  components: {
    SearchDataTable,
    PeriodoPicker,
    BtnFiltro,
    DetalleCartera
  },
  methods: {
    async tiene_recibo (item) {
      if (!item.recibo) {
        try {
          this.$store.state.loading = true
          const result = await this.$store.dispatch('cartera/tiene_recibo', item)
          item.recibo = result.data
          this.$store.state.loading = false
        } catch (error) {
          this.$store.state.loading = false
          return this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
      this.$store.dispatch('show_snackbar', {
        text: item.recibo,
        color: 'purple'
      })
    },
    async buscar () {
      if (!(this.filtro.periodo || this.filtro.empresa || this.filtro.preimpreso || this.filtro.grupo || this.filtro.orden || this.filtro.titular)) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar al menos un filtro',
          color: 'warning'
        })
      }
      this.carteras = []
      this.search = ''
      this.load = true
      await this.$store.dispatch('cartera/get_carteras', this.filtro)
        .then((res) => {
          this.carteras = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    async limpiar () {
      // estas dos lienas se utilizan para que limpie visualmente el mes seleccionado en el PeriodoPicker
      this.filtro.periodo = null
      await new Promise(resolve => setTimeout(resolve, 1))
      this.filtro = {
        periodo: null,
        familia: null,
        empresa: null,
        preimpreso: '',
        grupo: '',
        orden: '',
        titular: ''
      }
    }
  }
}
</script>