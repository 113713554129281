<template>
  <div>
    <v-dialog v-model="dialog" max-width="800" scrollable>
      <v-card>
        <v-card-title class="d-flex mt-0 mx-md-2 mx-n1 mb-0">
          Modelos
          <v-spacer></v-spacer>
          <v-btn
            style="margin-right: 0.4rem"
            title="Sincronizar"
            color="success"
            small
            fab
            @click="handleSync"
            ><v-icon>fas fa-sync</v-icon></v-btn
          >
          <v-btn icon @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="buscar">
            <v-row class="my-2">
              <v-col cols="12" sm="3" md="3">
                Empresa
                <v-autocomplete
                  @change="setCodigosModelos"
                  v-model="filtro.empresa"
                  item-text="nombre"
                  return-object
                  :items="familias"
                  hide-details
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                Codigo Modelo
                <v-autocomplete
                  @change="setModelo"
                  v-model="filtro.codigoModelo"
                  item-text="Modelo"
                  item-value="Modelo"
                  tabindex="1"
                  :items="codigosModelosFiltrados"
                  hide-details
                  outlined
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                Modelo
                <v-text-field
                  readonly
                  v-model="filtro.modelo"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="pt-9">
                  <v-switch
                    v-model="filtro.switch"
                    class="mt-n1 ml-4"
                    :label="filtro.switch ? 'Habilitados' : 'Inhabilitados'"
                    hide-details
                  ></v-switch>
              </v-col>

              <!-- <v-col cols="12" md="3" class="pt-0"> -->
              <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
              <!-- </v-col> -->
            </v-row>
          </v-form>

          <v-data-table
            class="cebra elevation-2 mt-2"
            item-key="id"
            sort-by="id"
            :headers="headers"
            :items="modelosFiltrados"
            :loading="load"
            sort-desc
            dense
          >
            <template v-slot:[`item.valor_venta`]="{ item }">
              {{ item.valor_venta | formatMoney }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div
                style="
                  display: flex;
                  gap: 3px;
                  align-items: center;
                  justify-content: center;
                "
              >
                <BtnConfirmar
                  :icono="item.Inhabilitado ? 'fas fa-check' : 'fas fa-ban'"
                  :color="item.Inhabilitado ? 'success' : 'error'"
                  :title="item.Inhabilitado ? 'Habilitar' : 'Inhabilitar'"
                  :texto="
                    item.Inhabilitado
                      ? `¿Desea habilitar el modelo <strong>${item.Modelo}</strong>?`
                      : `¿Desea inhabilitar el modelo <strong>${item.Modelo}</strong>?`
                  "
                  :icon_button="true"
                  :small="true"
                  @action="
                    item.Inhabilitado ? habilitar(item) : inhabilitar(item)
                  "
                />
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BtnConfirmar from "../util/BtnConfirmar.vue";
import BtnFiltro from "../util/BtnFiltro.vue";

export default {
  data() {
    return {
      load: false,
      headers: [
        { text: "Marca", value: "empresa_nombre" },
        { text: "Cod. Modelo", value: "Modelo" },
        { text: "Modelo", value: "Descripcion" },
        { text: "Acciones", value: "actions", width: "20px", sortable: false },
      ],
      modelos: [],
      modelosFiltrados: [],
      codigosModelosFiltrados: [],
      filtro: {
        empresa: {},
        codigoModelo: null,
        modelo: null,
        switch: true,
      },
    };
  },

  async created() {
    if (this.familias.length == 1) {
      this.filtro.empresa = this.familias[0].id;
      this.getModelos();
    }
  },

  methods: {
    async buscar() {
      if (Object.keys(this.filtro.empresa).length == 0) {
        this.$store.dispatch("show_snackbar", {
          color: "warning",
          text: "Debes seleccionar una empresa.",
        });
      } else {
        await this.getModelos();

        this.modelosFiltrados = this.modelos.filter((modelo) => {
          if (this.filtro.codigoModelo) {
            return modelo.Modelo == this.filtro.codigoModelo;
          } else {
            return modelo.Inhabilitado === !this.filtro.switch;
          }
        });
      }
    },

    async getModelos() {
      this.$store.state.loading = true;
      await this.$store
        .dispatch("modelos/getModelos", this.filtro.empresa.id)
        .then((res) => {
          if (res.exito) {
            this.modelos = res.data;
            this.modelos.forEach((modelo) => {
              modelo.empresa_nombre = this.empresas.find(
                (empresa) => empresa.id === modelo.Empresa_id
              )?.nombre;
            });

            this.modelos.sort((a, b) => {
              if (a.Inhabilitado === b.Inhabilitado) {
                return 0;
              } else if (a.Inhabilitado) {
                return 1;
              } else {
                return -1;
              }
            });
          }
        });
      this.$store.state.loading = false;
    },

    async setCodigosModelos() {
      this.filtro.codigoModelo = null;
      this.filtro.modelo = null;
      if (Object.keys(this.filtro.empresa).length == 0) {
        this.$store.dispatch("show_snackbar", {
          color: "warning",
          text: "Debes seleccionar una empresa.",
        });
      } else {
        await this.getModelos();
        this.codigosModelosFiltrados = this.modelos.filter((modelo) => {
          if (this.filtro.empresa.id == 1) {
            return modelo.Empresa_id == 1;
          } else {
            return modelo.Empresa_id != 1;
          }
        });
      }
    },

    setModelo() {
      if (this.filtro.codigoModelo) {
        this.codigosModelosFiltrados = this.modelos.filter((modelo) => {
          return this.filtro.codigoModelo == modelo.Modelo;
        });

        this.filtro.modelo = this.codigosModelosFiltrados[0].Descripcion;
      } else {
        this.filtro.modelo = null;
        this.codigosModelosFiltrados = this.modelos.filter((modelo) => {
          if (this.filtro.empresa.id == 1) {
            return modelo.Empresa_id == 1;
          } else {
            return modelo.Empresa_id != 1;
          }
        });
      }
    },

    async handleSync() {
      if (Object.keys(this.filtro.empresa).length == 0) {
        this.$store.dispatch("show_snackbar", {
          color: "warning",
          text: "Debes seleccionar una empresa.",
        });
      } else {
        this.$store.state.loading = true;
        await this.$store
          .dispatch("modelos/sincronizarModelos", this.filtro.empresa.id)
          .then(() => {
            this.$store.dispatch("show_snackbar", {
              color: "success",
              text: "Los modelos se actualizaron con éxito.",
            });
          })
          .catch((error) => {
            this.$store.dispatch("show_snackbar", {
              color: "success",
              text:
                "Ocurrió un error al actualizar los modelos, " + error.message,
            });
          });
        this.$store.state.loading = false;
      }
    },

    async inhabilitar(item) {
      const data = {
        modelo: item.Modelo,
        action: true,
        marca: item.Empresa_id,
        sai_modelo_id: item.sai_modelo_id,
      };

      this.$store.state.loading = true;
      await this.$store
        .dispatch("modelos/gestionarModelo", data)
        .then((res) => {
          this.$store.dispatch("show_snackbar", {
            color: "success",
            text: res.message,
          });
        });
      item.Inhabilitado = true;
      this.$forceUpdate();
      this.$store.state.loading = false;
    },

    async habilitar(item) {
      const data = {
        modelo: item.Modelo,
        action: false,
        marca: item.Empresa_id,
        sai_modelo_id: item.sai_modelo_id,
      };

      this.$store.state.loading = true;
      await this.$store
        .dispatch("modelos/gestionarModelo", data)
        .then((res) => {
          this.$store.dispatch("show_snackbar", {
            color: "success",
            text: res.message,
          });
        });
      item.Inhabilitado = false;
      this.$forceUpdate();
      this.$store.state.loading = false;
    },

    limpiarFiltro() {
      this.filtro.empresa = {};
      this.filtro.codigoModelo = null;
      this.filtro.modelo = null;
    },
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState(["familias", "empresas"]),

    modelosSeteados: {
      get() {
        return this.modelos.map((modelo) => ({ ...modelo }));
      },
      set(nuevosModelos) {
        this.modelos = nuevosModelos;
      },
    },
  },

  watch: {
    dialog(value) {},

    'filtro.switch'(value){
      this.modelosFiltrados = this.modelos.filter(modelo => modelo.Inhabilitado == !value)
    }
  },

  props: {
    value: Boolean,
  },

  components: {
    BtnConfirmar,
    BtnFiltro,
  },
};
</script>
