<template>
  <v-dialog
    v-model="dialog"
    width="600"
  >
    <v-card>
      <v-card-title>
        Informe liquidación {{ periodo }} - {{ familia != null ? familias.find(fa => fa.id == familia).nombre : '' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-8">
        <v-row> 
          <v-col cols="12" sm="6" class="py-1">
            <v-autocomplete
              v-model="supervisor"
              label="Supervisor"
              item-value="id"
              item-text="nombre"
              :items="supervisores.filter(sup => sup.familia_id == familia)"
              hide-details
              clearable
              outlined
              dense
              @change="vendedor = null"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" class="py-1">
            <v-autocomplete
              v-model="vendedor"
              label="Vendedor"
              item-value="id"
              item-text="nombre"
              :items="vendedores.filter(v => v.supervisor == supervisor)"
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          @click="descargar()"
        >
          <v-icon left>fas fa-download</v-icon>
          Descargar
        </v-btn>
      </v-card-actions>
    </v-card>
    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { parseDate } from '../../util/utils'
import { informeComisiones } from '../../util/plantillas/pdfs'
import moment from 'moment'
import PDFViewer from '../../util/plantillas/PDFViewer'

export default {
  data () {
    return {
      dialog_pdf: false,
      pdf: null,
      pdf_nombre: '',
      supervisor: '',
      vendedor: ''
    }
  },
  props: {
    value: Boolean,
    periodo: String,
    familia: Number
  },
  computed: {
    ...mapState(['familias']),
    ...mapState('genericos', ['vendedores', 'supervisores']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    PDFViewer
  },
  watch: {
    dialog (val) {
      if (val) {
        this.supervisor = ''
        this.vendedor = ''
      }
    }
  },
  methods: {
    async descargar () {
      if (!this.supervisor && !this.vendedor) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un supervisor o un vendedor',
          color: 'warning'
        })
      }
      this.dialog = false
      this.$store.state.loading = true
      await this.$store.dispatch('comisiones/get_datos_pdf', {
        supervisor: this.supervisor,
        vendedor: this.vendedor,
        periodo: this.periodo,
        familia: this.familia
      })
        .then(async (res) => {
          this.pdf_nombre = `Liquidación ${moment(parseDate(`01/${this.periodo}`)).format('MM-YYYY')} - `
          if (this.vendedor) {
            this.pdf_nombre += `${this.vendedores.find(v => v.id == this.vendedor).nombre}`
          } else {
            this.pdf_nombre += `${this.supervisores.find(s => s.id == this.supervisor).nombre}`
          }
          this.pdf_nombre += '.pdf'
          this.pdf = await informeComisiones(res.data)
          this.dialog_pdf = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    }
  }
}
</script>